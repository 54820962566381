import React, { memo } from 'react';
import classnames from 'classnames';
import './style.scss';
import Icon, { IconTypes } from '../Icon/Icon';
import Link from '../Link/Link';

export enum ButtonLinkType {
  PRIMARY = 'ButtonLink__primary',
  // SECONDARY = 'ButtonLink_secondary'
}

export enum ButtonLinkSize {
  SMALL = 'ButtonLink__small',
  LARGE = 'ButtonLink__large',
  // SECONDARY = 'SECONDARY'
}
export interface ButtonLinkLinkOptions {
  isExternalLink?: boolean;
  shouldOpenInNewTab?: boolean; // This option will be overridden if the external link is true
  shouldEnableActiveState?: boolean; // Only gets affected if isExternalLink is false
}

export interface ButtonLinkStyleOptions {
  isInline?: boolean;
  type?: ButtonLinkType;
  size?: ButtonLinkSize;
  invertColors?: boolean;
}

export interface ButtonLinkIconOptions {
  icon: IconTypes;
  position?: 'left' | 'right'; // default is always left
}

interface AnchorLinkProps {
  href?: string;
  target?: string;
  rel?: string;
}

export interface OwnProps {
  to: string;
  linkOptions?: ButtonLinkLinkOptions;
  iconOptions?: ButtonLinkIconOptions;
  children?: React.ReactNode;
  className?: string;
  styleOptions?: ButtonLinkStyleOptions;
  aLinkProps?: AnchorLinkProps;
  ariaLabel?: string;
}

type Props = OwnProps;

const defaultLinkOptions: ButtonLinkLinkOptions = {
  isExternalLink: false,
  shouldOpenInNewTab: false, // This option will be overridden if the external link is true
  shouldEnableActiveState: true, // Only gets affected if isExternalLink is false
};

const defaultStyleOptions: ButtonLinkStyleOptions = {
  isInline: false,
  type: ButtonLinkType.PRIMARY,
  size: ButtonLinkSize.LARGE,
  invertColors: false,
};

const ButtonLink: React.FC<Props> = ({
  to,
  linkOptions,
  iconOptions,
  styleOptions,
  className = '',
  // onClick = () => {},
  aLinkProps,
  children = null,
  ariaLabel,
}: Props) => {
  const styleOptionsWithDefault: ButtonLinkStyleOptions = {
    ...defaultStyleOptions,
    ...styleOptions,
  };

  const linkOptionsWithDefault: ButtonLinkLinkOptions = {
    ...defaultLinkOptions,
    ...linkOptions,
  };

  const classNames = classnames(
    `ButtonLink`,
    styleOptionsWithDefault.type,
    styleOptionsWithDefault.size,
    className,
    {
      'ButtonLink__inline-link': !!styleOptions?.isInline,
      'ButtonLink__invert-colors': !!styleOptions?.invertColors,
      small: styleOptionsWithDefault.size === ButtonLinkSize.SMALL,
    }
  );

  const aLinkPropsDefault = {
    href: to,
    target: '__blank',
    rel: 'noopener',
    ...aLinkProps,
  };

  const linkProps = {
    to,
    partiallyActive: !!linkOptionsWithDefault.shouldEnableActiveState,
    activeClassName: linkOptionsWithDefault?.shouldEnableActiveState
      ? 'ButtonLink--active'
      : undefined,
  };

  const getIcon = () => {
    if (iconOptions) {
      const IconComp = ({ className_ }: any) => (
        <span className={className_}>
          <Icon type={iconOptions.icon} />
        </span>
      );
      return (
        <IconComp
          className_={`ButtonLink__icon ButtonLink__icon-${iconOptions?.position || 'left'}`}
        />
      );
    }
    return React.Fragment;
  };

  return linkOptionsWithDefault.isExternalLink ? (
    <a
      role="link"
      className={classNames}
      {...aLinkPropsDefault}
      {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
    >
      {(!iconOptions?.position || iconOptions?.position === 'left') && getIcon()}
      {children}
      {iconOptions?.position === 'right' && getIcon()}
    </a>
  ) : (
    <Link className={classNames} {...linkProps} {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}>
      {(!iconOptions?.position || iconOptions?.position === 'left') && getIcon()}
      {children}
      {iconOptions?.position === 'right' && getIcon()}
    </Link>
  );
};

export default memo(ButtonLink);
