import RouteKeys from '../../../config/RouteKeys';

const links: any = {
  cbaAboutMoneyStudents: {
    en: 'https://cba.ca/about-your-money-students?l=en-us',
    fr: 'https://cba.ca/about-your-money-students?l=fr',
  },
  cbaAboutMoneySeniors: {
    en: 'https://cba.ca/about-your-money-seniors?l=en-us',
    fr: 'https://cba.ca/about-your-money-seniors?l=fr',
  },
  cbaFraudPrevention: {
    en: 'https://cba.ca/?tag=fraud-prevention-toolkits',
    fr: 'https://cba.ca/?tag=fraud-prevention-toolkits&l=fr',
  },
  fcaELearning: {
    en:
      'https://www.canada.ca/en/financial-consumer-agency/services/financial-basics/financial-basics-videos.html',
    fr:
      'https://www.canada.ca/fr/agence-consommation-matiere-financiere/services/finances-personnelles/finances-personnelles-videos.html',
  },
  fcaMortgageToolkit: {
    en:
      'https://www.canada.ca/en/financial-consumer-agency/services/financial-toolkit/mortgages.html',
    fr:
      'https://www.canada.ca/fr/agence-consommation-matiere-financiere/services/vos-outils-financiers/hypotheques.html',
  },
  fcaFinancialToolkit: {
    en: 'https://www.canada.ca/en/financial-consumer-agency/services/financial-toolkit.html',
    fr:
      'https://www.canada.ca/fr/agence-consommation-matiere-financiere/services/vos-outils-financiers.html',
  },
  fcaCreditScore: {
    en:
      'https://www.canada.ca/en/financial-consumer-agency/services/credit-reports-score/credit-report-score-basics.html#toc0',
    fr:
      'https://www.canada.ca/fr/agence-consommation-matiere-financiere/services/dossier-pointage-credit/dossier-cote-credit-base.html',
  },
  pcTrove: {
    en: 'https://yourtrove.org/',
    fr: 'https://yourtrove.org/fr/',
  },
  pcFinancialRelief: {
    en: 'https://financialreliefnav.prospercanada.org/en',
    fr: 'https://exploallegementsfinanciers.prospercanada.org/fr',
  },
  pcMoneyManagement: {
    en: 'https://moneymanagement.prospercanada.org/',
    fr: 'https://moneymanagement.prospercanada.org/fr/',
  },
  pcTaxFilingKit: {
    en: 'https://yourtrove.org/learn-about-tax-filing-and-benefits',
    fr:
      'https://yourtrove.org/fr/pour-en-savoir-plus-sur-la-declaration-de-revenus-et-les-prestations',
  },
  pcDebtDealing: {
    en: 'https://yourtrove.org/start-tackling-your-debt-today',
    fr: 'https://yourtrove.org/fr/attaquez-vous-a-votre-dette-des-aujourd-hui',
  },
  pcTakeChargeSpending: {
    en: 'https://yourtrove.org/take-charge-of-your-spending',
    fr: 'https://yourtrove.org/fr/prenez-vos-depenses-en-main',
  },
  pcCreditReport: {
    en: 'https://moneymanagement.prospercanada.org/module_home/credit-reports/',
    fr: 'https://moneymanagement.prospercanada.org/fr/introduction/credit-and-credit-reports/',
  },
  budgetPlanner: {
    type: 'internal',
    path: RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_TOOLS_RESOURCES.BUDGET_PLANNER,
  },
};

export default links;
