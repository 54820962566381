import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, Trans, withTranslation } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import PageMenu from '../../../../components/PageMenu/PageMenu';
import mortgageResourcesMenu from '../../../../data/page-menus/mortgage-resources';
import links from '../../../../data/customer-center/financialToolsLinks';
import ExternalLink from '../../../../components/ExternalLink/ExternalLink';
import ButtonLink from '../../../../components/ButtonLink/ButtonLink';
import { getPathFromRouteKey } from '../../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../../config/RouteKeys';
import { IconTypes } from '../../../../components/Icon/Icon';
import { Accordion, AccordionItem } from '../../../../components/Accordion/Accordion';
import { useLocaleContext } from '../../../../context/Locale';

import './style.scss';

type Props = PageProps & WithTranslation;

const FinancialLiteracy: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('financialLiteracy.seo.title'),
    },
    page: {
      title: t('financialLiteracy.heading'),
    },
    heroSection: {
      options: {
        heroImage: {
          fileName: 'hero-image-13.jpg',
          alt: 'Hero Image',
        },
        accentColor: HeroSectionColors.SKY_BLUE,
      },
    },
  };

  const generateList = (list: any[]) => {
    return (
      <>
        <h2 className="Accordion__Title">{t('financialLiteracy.caseStudies.heading')}</h2>
        <Accordion allowZeroExpanded allowMultipleExpanded>
          {list.map((item, i) => {
            return (
              <AccordionItem key={`${item.question}-${i}`} buttonOptions={{ label: item.question }}>
                {/* eslint-disable-next-line react/no-danger */}
                {(item.answerList as { heading: string; body: string; linkTag?: string }[]).map(
                  ({ heading, body, linkTag }) => (
                    <>
                      <h3 className="h6 accordion-item-heading">{heading}</h3>
                      <p>
                        <Trans
                          components={[
                            linkTag ? (
                              <ExternalLink
                                key={`${heading}-link`}
                                href={links[linkTag as string][activeLocaleTag]}
                              />
                            ) : null,
                          ]}
                        >
                          {body}
                        </Trans>
                      </p>
                    </>
                  )
                )}
              </AccordionItem>
            );
          })}
        </Accordion>
      </>
    );
  };

  return (
    <Layout options={layoutOptions} className="FinancialLiteracy">
      <div className="grid-container ">
        <div className="row row-wrap FinancialLiteracy__generic-gap">
          <section className="column column-60">
            {/* <h2>{t('financialLiteracy.empowering.heading')}</h2> */}
            <p className="FinancialLiteracy__body-generic-gap">
              <Trans t={t} i18nKey="financialLiteracy.empowering.body" />
            </p>
            <div className="FinancialLiteracy__generic-gap">
              {generateList(t('financialLiteracy.caseStudies.questions', { returnObjects: true }))}
            </div>
            <div className="FinancialLiteracy__generic-gap">
              <h2>{t('financialLiteracy.externalTools.heading')}</h2>
              <p className="FinancialLiteracy__body-generic-gap">
                {t('financialLiteracy.externalTools.body')}
              </p>
              <div className="FinancialLiteracy__mortgage-calculator-button">
                <ButtonLink
                  to={getPathFromRouteKey(
                    RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_TOOLS_RESOURCES
                  )}
                  styleOptions={{ isInline: true }}
                  iconOptions={{ icon: IconTypes.ARROW_NEXT }}
                >
                  {t('financialLiteracy.externalTools.buttonLabel')}
                </ButtonLink>
              </div>
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={mortgageResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.mortgageResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(FinancialLiteracy);
