import RouteKeys from '../../../config/RouteKeys';
import { PageMenuLinks } from './pageMenuTypes';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const mortgageResourcesMenu: PageMenuLinks = [
  {
    to: getPathFromRouteKey(RouteKeys.BORROWER),
    textKey: 'pageTitles.clientPortal',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.PREPAYMENT_CALCULATOR),
    textKey: 'pageTitles.prepaymentCalculator',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.UNDERSTANDING_YOUR_MORTGAGE),
    textKey: 'pageTitles.understandingYourMortgage',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.MORTGAGE_SERVICE_DOCUMENTS),
    textKey: 'pageTitles.mortgageServiceDocuments',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.HOME_WARRANTY),
    textKey: 'pageTitles.homeWarranty',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.NEXTJOB_PROGRAM),
    textKey: 'pageTitles.nextJobProgram',
  },
  {
    to: getPathFromRouteKey(RouteKeys.ABOUT_US.SCC),
    textKey: 'pageTitles.scc',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_LITERACY),
    textKey: 'pageTitles.financialLiteracy',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_TOOLS_RESOURCES),
    textKey: 'pageTitles.financialTools',
  },
  // {
  //   to: getPathFromRouteKey(RouteKeys.CCP),
  //   textKey: 'pageTitles.promotions',
  // },
];

export default mortgageResourcesMenu;
