import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconTypes } from '../Icon/Icon';
import { PageMenuLinks } from '../../data/page-menus/pageMenuTypes';
import ButtonLink from '../ButtonLink/ButtonLink';
import './style.scss';

export interface OwnProps {
  menuTitle?: string;
  menu: PageMenuLinks;
  className?: string;
}

type Props = OwnProps;

const PageMenu: React.FC<Props> = ({ menu, menuTitle = '', className = '' }: Props) => {
  const { t } = useTranslation();

  const getMenuContent = () => {
    return menu.map((menuItem, i) => (
      <li key={`${menuItem.textKey}-${i}`}>
        <ButtonLink
          to={menuItem.to}
          iconOptions={{ icon: IconTypes.ARROW_NEXT }}
          {...('enableActiveState' in menuItem && {
            linkOptions: {
              shouldEnableActiveState: menuItem.enableActiveState,
            },
          })}
        >
          {t(menuItem.textKey)}
        </ButtonLink>
      </li>
    ));
  };

  return (
    <div className={`PageMenu ${className}`}>
      {menuTitle && <h2 className="h3">{menuTitle}</h2>}
      <ul>{getMenuContent()}</ul>
    </div>
  );
};

export default memo(PageMenu);
